import {
  BusinessPartner_ComplianceCheck_ErrorMessageConfig,
  BusinessPartner_ComplianceCheck_LeadCheckConfig,
  CONTACT_REQUIRED_FIELDS,
  Contact_CoinCheck_SellingNotAllowed_Message,
  Contact_ComplianceCheck_ContactRoles_ErrorMessage,
  Contact_ComplianceCheck_ContractRoles,
  Contact_ComplianceChecks_LeadCheck,
  NKAM_AllGeneric_StartDateSetBeforePresentDay,
  NKAM_BusinessPartner_Missing_GLOBUSId_Message,
  NKAM_ESOSearch_CountryList,
  NKAM_ESO_Search_Dialog,
  NKAM_Globus_Status_Lock,
  PAGE_SIZE,
  PANEL_PAGE_SIZE,
  PRIMARY_LANGUAGE_CONFIG,
  SUSPECT_VALUE,
  VIEW,
  NKAM_Contact_StreetPOBoxCheck,
  Portal_BusinessPartner_PrimaryContact_Error,
  NKAM_Contract_AllowedStatusMovement,
  NKAM_BusinessPartner_Missing_GCID_Message,
  LEAD_VALUE,
  CUSTOMER_VALUE,
  LEVEL3_VALUE,
  NATIONAL_ID1_LABEL,
  NATIONAL_ID2_LABEL,
  NATIONAL_ID3_LABEL,
  ALLOW_OWNING_KAM_EDIT,
  NKAM_BusinessPartner_StreetPOBoxCheck,
  NKAM_Default_InvoiceLanguage,
  BusinessPartner_VatNotApplicable_Config,
  BusinessPartner_VatNotApplicableMessage_Config,
  BusinessPartner_CloneVAT_Config,
  INPUT,
  NKAM_BusinessPartner_GCID_ENABLED,
} from "@/constants/constants";
import { ContactEntity } from "@/constants/forms";
import apiUIData from "./apiUI";
import { useQueries, useQuery } from "@tanstack/react-query";
import apiContactsData from "./apiContacts";
import apiBPData from "./apiBusinessPartners";
import apiContractsData from "./apiContracts";
import apiOrdersData from "./apiOrders";
import { checkCoinCheck } from "@/helperFunctions/contRacts";
import apiDiscountMatrixData from "./apiDiscountMatrix";
import apiTacticalCampaignsData from "./apiTacticalCampaigns";
import apiRetailersData from "./apiRetailers";
import { getNkamStatusConfig } from "@/helperFunctions/businessPartners";

export function useContactInitialQueries() {
  let initialQueries = [
    {
      key: "departmentOptions",
      func: apiUIData.getDepartmentOptions,
      params: [],
    },
    {
      key: "emailBouncBackOptions",
      func: apiUIData.getEmailBounceBackOptions,
      params: [],
    },
    {
      key: "jobFunctionOptions",
      func: apiUIData.getJobFunctionOptions,
      params: [],
    },
    {
      key: "positionOptions",
      func: apiUIData.getPositionOptions,
      params: [],
    },
    {
      key: "primaryLanguageConfig",
      func: apiUIData.getCrmConfiguration,
      params: [PRIMARY_LANGUAGE_CONFIG],
    },
    {
      func: apiUIData.getTypeOptions,
      params: [],
      key: "typeOptions",
    },
  ];

  return useQueries({
    queries: initialQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key],
        queryFn: async ({ signal }) => func(...(params || signal), signal),
      };
    }),
  });
}

export function useContactAndBpDetails(id, bpid) {
  return useQueries({
    queries: [
      {
        queryKey: ["contactDetails", { id }],
        queryFn: async ({ signal }) =>
          apiContactsData.getContactDetail(id, signal),
        enabled: Boolean(id),
      },
      {
        queryKey: ["bpDetails", bpid],
        queryFn: async ({ signal }) =>
          apiBPData.getBusinessPartnerPermissions(bpid, signal),
        enabled: Boolean(bpid),
      },
    ],
  });
}

export function useContactMainInitialQueries() {
  let initialQueries = [
    {
      key: "contactRequiredFields",
      func: apiUIData.getCrmConfiguration,
      params: [CONTACT_REQUIRED_FIELDS],
    },
    {
      key: "Contact_ComplianceCheck_ContractRoles",
      func: apiUIData.getCrmConfiguration,
      params: [Contact_ComplianceCheck_ContractRoles],
    },
    {
      key: "Contact_ComplianceCheck_ContactRoles_ErrorMessage",
      func: apiUIData.getCrmConfiguration,
      params: [Contact_ComplianceCheck_ContactRoles_ErrorMessage],
    },
    {
      key: "Contact_CoinCheck_SellingNotAllowed_Message",
      func: apiUIData.getCrmConfiguration,
      params: [Contact_CoinCheck_SellingNotAllowed_Message],
    },
    {
      key: "NKAM_Contact_StreetPOBoxCheck",
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_Contact_StreetPOBoxCheck],
    },
  ];

  return useQueries({
    queries: initialQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key],
        queryFn: async ({ signal }) => func(...(params || signal), signal),
      };
    }),
  });
}

export function useCountyListQuery(isCountrySelected) {
  return useQuery({
    queryKey: ["isCountrySelected", isCountrySelected],
    queryFn: async ({ signal }) => {
      return apiUIData.getCountyList(isCountrySelected, signal);
    },
    enabled: Boolean(isCountrySelected),
  });
}

export function useContractInitialDataQueries() {
  let initialQueries = [
    {
      key: "getContractCategoryOptions",
      func: apiUIData.getContractCategoryOptions,
      params: [],
    },
    {
      key: "getContractStatusOptions",
      func: apiUIData.getContractStatusOptions,
      params: [],
    },
    {
      key: "getSalesChannelOptions",
      func: apiUIData.getSalesChannelOptions,
      params: [],
    },
    {
      key: "getUpfrontDiscountMatrixOptions",
      func: apiUIData.getUpfrontDiscountMatrixOptions,
      params: [],
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_Contract_AllowedStatusMovement],
      key: "ContractAllowedStatusMovement",
    },
  ];

  return useQueries({
    queries: initialQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key],
        queryFn: async ({ signal }) => func(...(params || signal), signal),
      };
    }),
  });
}

export function useDiscountMatrixFilteredDataQueries(
  salesModelType,
  businessPartnerId,
) {
  return useQuery({
    queryKey: [
      "useDiscountMatrixFilteredDataQueries",
      salesModelType,
      businessPartnerId,
    ],
    enabled: salesModelType || businessPartnerId ? true : false,
    queryFn: async ({ signal }) => {
      return apiUIData.getFilteredUpfrontDiscountMatrixOption(
        salesModelType,
        businessPartnerId,
        signal,
      );
    },
  });
}

export function useContractDetailsQueries(id, sentForApproval) {
  return useQueries({
    queries: [
      {
        queryKey: ["contractDetails", { id }, sentForApproval],
        queryFn: async ({ signal }) =>
          apiContractsData.getContractsDetail(id, signal),
        enabled: Boolean(id),
      },
      {
        queryKey: ["permissionToEditContract", { id }, sentForApproval],
        queryFn: async ({ signal }) =>
          apiContractsData.permissionToEditContract(id, signal),
        enabled: Boolean(id),
      },
    ],
  });
}

export function useContractApprovalQueries(
  entity,
  id,
  sentForApproval,
  updateTimeline,
) {
  return useQueries({
    queries: [
      {
        queryKey: [
          "getApprovalButtonVisibility",
          { id },
          entity,
          sentForApproval,
          updateTimeline,
        ],
        queryFn: async ({ signal }) =>
          apiUIData.getApprovalButtonVisibility(id, entity, signal),
      },
      {
        queryKey: [
          "getApprovalTypes",
          { id },
          entity,
          sentForApproval,
          updateTimeline,
        ],
        queryFn: async ({ signal }) =>
          apiUIData.getApprovalTypes(entity, signal),
      },
    ],
  });
}

export function useOrdersByContRact(contractId, page) {
  return useQuery({
    queryKey: ["getOrdersByContract", contractId, page],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrdersByContract(
        contractId,
        PANEL_PAGE_SIZE,
        page,
        signal,
      );
    },
    enabled: Boolean(contractId),
  });
}

export function useContractTemplateOptions(salesChannelId) {
  return useQuery({
    queryKey: ["getContractTemplateOptions", salesChannelId],
    queryFn: async ({ signal }) => {
      return apiUIData.getContractTemplateOptions(salesChannelId, signal);
    },
    enabled: Boolean(salesChannelId),
  });
}

export function useContRactCoinCheck(bpid, salesModel) {
  return useQuery({
    queryKey: ["getContRactCoinCheck", salesModel],
    queryFn: async ({ signal }) => {
      return await checkCoinCheck(bpid, signal);
    },
    enabled: Boolean(salesModel?.name?.toLowerCase() === "direct"),
  });
}

export function useFetchContRacts(
  searchText,
  quickFilter,
  page,
  debouncedSearch,
  pageSize,
) {
  return useQuery({
    queryKey: ["fetchContRacts", [debouncedSearch, page, quickFilter]],
    queryFn: async ({ signal }) => {
      return await apiContractsData.searchContracts(
        pageSize,
        page,
        searchText,
        quickFilter,
        signal,
      );
    },
  });
}

export function useDiscountMatrixData(id) {
  return useQueries({
    queries: [
      {
        queryKey: ["getDiscountMatrix", id],
        queryFn: async ({ signal }) => {
          return apiDiscountMatrixData.getDiscountMatrix(id, signal);
        },
        enabled: Boolean(id),
      },
      {
        queryKey: ["getDiscountLineItems", id],
        queryFn: async ({ signal }) => {
          return apiDiscountMatrixData.getDiscountLineItems(id, signal);
        },
        enabled: Boolean(id),
      },
    ],
  });
}

export function useSearchDunsQuery(
  nameSearch,
  citySearch,
  postcodeSearch,
  companyCodesSearch,
  debouncedSearch,
) {
  return useQuery({
    queryKey: ["searchDuns", debouncedSearch],
    queryFn: ({ signal }) =>
      apiBPData.searchDUNSData(
        nameSearch,
        citySearch,
        postcodeSearch,
        companyCodesSearch,
        "",
        signal,
      ),
    enabled: Boolean(nameSearch !== "") || Boolean(companyCodesSearch !== ""),
  });
}

export function useESOFormFieldsQuery() {
  return useQuery({
    queryKey: ["getESOFormFields"],
    queryFn: async ({ signal }) => {
      return apiUIData.getCrmConfiguration(NKAM_ESO_Search_Dialog, signal);
    },
  });
}

export function useESOCountryOptionsQuery() {
  return useQuery({
    queryKey: ["getESOCountryOptions"],
    queryFn: async ({ signal }) => {
      return apiUIData.getCrmConfiguration(NKAM_ESOSearch_CountryList, signal);
    },
  });
}

export function useGetOrderDetailsQuery(id, mode, sentForApproval) {
  return useQuery({
    queryKey: ["getOrderDetails", { id, mode, sentForApproval }],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrdersDetail(id, signal);
    },
    enabled: Boolean(id),
  });
}

export function useGetOrderVerificationInstructionsQuery() {
  return useQuery({
    queryKey: ["getOrderVerificationInstructions"],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrderVerificationInstructions(signal);
    },
  });
}

export function useGetOrderOptionsQuery(id, page) {
  return useQuery({
    queryKey: ["getOrderOptions", id, page],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrderOptions(id, PANEL_PAGE_SIZE, page, signal);
    },
  });
}

export function useGetOrderTacticalCampaignsQuery(id, page) {
  return useQuery({
    queryKey: ["getOrderTacticalCampaigns", id, page],
    queryFn: async ({ signal }) => {
      return apiOrdersData.getOrderTacticalCampaigns(
        id,
        PANEL_PAGE_SIZE,
        page,
        signal,
      );
    },
  });
}

export function useFetchOrders(
  searchText,
  quickFilter,
  page,
  debouncedSearch,
  pageSize,
) {
  return useQuery({
    queryKey: ["fetchOrders", [debouncedSearch, page, quickFilter]],
    queryFn: async ({ signal }) => {
      return await apiOrdersData.searchOrders(
        pageSize,
        page,
        searchText,
        quickFilter,
        signal,
      );
    },
  });
}

export function useFetchTacticalCampaigns(
  searchText,
  page,
  debouncedSearch,
  pageSize,
) {
  return useQuery({
    queryKey: ["searchTacticalCampaigns", [debouncedSearch, page]],
    queryFn: async ({ signal }) => {
      return await apiTacticalCampaignsData.searchTacticalCampaigns(
        pageSize,
        page,
        searchText,
        signal,
      );
    },
  });
}

export function useBPRoleInitialQueries() {
  const initialQueries = [
    {
      key: "NKAM_AllGeneric_StartDateSetBeforePresentDay",
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_AllGeneric_StartDateSetBeforePresentDay],
    },
    {
      key: "NKAM_AllGeneric_StartDateSetBeforePresentDay",
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_AllGeneric_StartDateSetBeforePresentDay],
    },
    {
      key: "getBPRoleTypeOptions",
      func: apiUIData.getBPRoleTypeOptions,
      params: [],
    },
  ];
  return useQueries({
    queries: initialQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key],
        queryFn: async ({ signal }) => func(...(params || signal), signal),
      };
    }),
  });
}

export function useGetBPRoleDetailsQuery(id, mode) {
  return useQuery({
    queryKey: ["getBPRoleDetails", { id }],
    queryFn: async ({ signal }) => {
      return apiBPData.getBusinessPartnerRoleDetail(id, signal);
    },
    enabled: Boolean(id) && Boolean(mode === VIEW) && Boolean(id !== "create"),
  });
}

export function useGetBPDetails(id) {
  return useQuery({
    queryKey: ["getBusinessPartnerDetail", { id }],
    queryFn: async ({ signal }) => {
      return apiBPData.getBusinessPartnerDetail(id, signal);
    },
    enabled: Boolean(id),
  });
}

export function useGetBPContacts(id, businessPartner) {
  return useQuery({
    queryKey: [
      "getBusinessPartnerContacts",
      { id },
      businessPartner?.primaryContact,
    ],
    queryFn: async ({ signal }) => {
      return apiContactsData.searchContactsData("", id, PAGE_SIZE, 1, signal);
    },
    enabled: Boolean(id) && Boolean(Object.keys(businessPartner).length),
  });
}

export function useBPInitialQueries(mode, isBpDetailsFetched) {
  const initialBPData = [
    {
      func: apiBPData.getBusinessPartnerTimelineConfig,
      params: null,
      key: "businessPartnerTimelineConfig",
      enabled: Boolean(isBpDetailsFetched),
    },
    {
      func: apiUIData.getCompanyHierarchyOptions,
      params: null,
      key: "companyHierarchyOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getDowngradeReasonOptions,
      params: null,
      key: "downgradeReasonOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getNkamStatusOptions,
      params: null,
      key: "nkamStatusOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getLegalEntityTypeOptions,
      params: null,
      key: "legalEntityTypeOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getPrintConfirmationTemplatesExists,
      params: null,
      key: "printConfirmationTemplatesExists",
      enabled: Boolean(isBpDetailsFetched),
    },
    {
      func: apiUIData.getSalesRegionOptions,
      params: null,
      key: "salesRegionOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getTypeOptions,
      params: null,
      key: "typeOptions",
      enabled: true,
    },
    {
      func: apiUIData.getInvoiceLanguageOptions,
      params: null,
      key: "invoiceLanguageOptions",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getOwningRetailerOptions,
      params: null,
      key: "owningRetailerOptions",
      enabled: true,
    },
    {
      func: apiBPData.getOwningKamServiceUsers,
      params: null,
      key: "owningKamServiceUsers",
      enabled: Boolean(mode !== VIEW),
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: Portal_BusinessPartner_PrimaryContact_Error,
      key: "primaryContactError",
      enabled: true,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_BusinessPartner_Missing_GCID_Message],
      key: "globusMissingMessage",
      enabled: true,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_BusinessPartner_GCID_ENABLED],
      key: "GCDMEnabled",
      enabled: true,
    },
  ];
  return useQueries({
    queries: initialBPData.map(({ func, params, key, enabled }) => {
      return {
        queryKey: [key],
        queryFn: async ({ signal }) => {
          return func(params || signal, signal);
        },
        enabled: enabled,
      };
    }),
  });
}

export function useFetchRetailersData(retailerList) {
  return useQueries({
    queries: retailerList.map(({ id, name, logicalName }) => {
      return {
        queryKey: ["retailerData", { id }],
        queryFn: async ({ signal }) => {
          let res = await apiRetailersData.getRetailerById(id, signal);

          return {
            res,
            logicalName,
            name,
            id,
          };
        },
        enabled: Boolean(retailerList.length),
      };
    }),
  });
}

export function useFetchBpPermissions(id) {
  const bpDataAndPermissionsQueries = [
    {
      key: "bpPermissions",
      func: apiBPData.getBusinessPartnerPermissions,
      params: [id],
    },
    {
      key: "permissionToViewContacts",
      func: apiBPData.getPermissionToViewBPContacts,
      params: [],
    },
    {
      key: "bpContractHasSignatory",
      func: apiBPData.getBpContactsHasSignatory,
      params: [id],
    },
  ];

  return useQueries({
    queries: bpDataAndPermissionsQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key, id],
        queryFn: async ({ signal }) => {
          return func(...(params || signal), signal);
        },
        enabled: Boolean(id),
      };
    }),
  });
}

export function useFetchBPCOmplianceChecks(bpStatus) {
  const bpComplianceChecksQueries = [
    {
      key: "contactComplyConfigRes",
      func: apiUIData.getCrmConfiguration,
      params: [Contact_ComplianceChecks_LeadCheck],
    },
    {
      key: "globusStatusLockRes",
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_Globus_Status_Lock],
    },
    {
      key: "bpComplyConfigRes",
      func: apiUIData.getCrmConfiguration,
      params: [BusinessPartner_ComplianceCheck_LeadCheckConfig],
    },
    {
      key: "globusMissingMessage",
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_BusinessPartner_Missing_GLOBUSId_Message],
    },
    {
      key: "bpComplyErrorMessage",
      func: apiUIData.getCrmConfiguration,
      params: [BusinessPartner_ComplianceCheck_ErrorMessageConfig],
    },
  ];

  return useQueries({
    queries: bpComplianceChecksQueries.map(({ key, func, params }) => {
      return {
        queryKey: [key, bpStatus],
        queryFn: async ({ signal }) => {
          return func(...params, signal);
        },
        enabled: Boolean(bpStatus === SUSPECT_VALUE),
      };
    }),
  });
}

export function useFetchOwnRetailerData(
  retailerId,
  hasOwningRetailerNotFound,
  retailerOptionsLength,
) {
  return useQuery({
    queryKey: ["ownRetailersData"],
    queryFn: async ({ signal }) => {
      return apiRetailersData.getRetailerById(retailerId, signal);
    },
    enabled:
      Boolean(retailerId) &&
      hasOwningRetailerNotFound &&
      Boolean(retailerOptionsLength),
  });
}

export function useFetchNkamStatusOptions(
  businessPartner,
  nkamStatusOptions,
  mode,
) {
  return useQuery({
    queryKey: ["nkamStatuses", businessPartner, nkamStatusOptions],
    queryFn: async ({ signal }) => {
      return apiUIData.getCrmConfiguration(
        getNkamStatusConfig(businessPartner?.nkamStatus?.name),
        signal,
      );
    },
    enabled:
      Boolean(businessPartner?.nkamStatus) &&
      Boolean(nkamStatusOptions.length) &&
      Boolean(mode !== VIEW),
  });
}

export function useFetchNewL2BPs(
  companyHierarchy,
  debouncedL2Value,
  mode,
  reason,
) {
  return useQuery({
    queryKey: ["l2BusinessPartners", [companyHierarchy, ...debouncedL2Value]],
    queryFn: async ({ signal }) => {
      return apiUIData.getLevel2BusinessPartners(debouncedL2Value, signal);
    },
    enabled:
      Boolean(mode !== VIEW) &&
      Boolean(companyHierarchy === LEVEL3_VALUE) &&
      Boolean(debouncedL2Value[0]) &&
      Boolean(reason === INPUT),
    staleTime: 10000,
  });
}

export const filterRequiredFieldsContact = (
  configRequiredFields,
  address1Line1,
  postbox,
) => {
  let filteringReqFields = configRequiredFields || [];
  const isStreetAndPostBoxMandatory =
    configRequiredFields.find((f) => f.SchemaName === ContactEntity.street1)
      ?.SchemaName &&
    configRequiredFields.find((f) => f.SchemaName === ContactEntity.poBox)
      ?.SchemaName;

  if (isStreetAndPostBoxMandatory) {
    if (!address1Line1 && postbox) {
      filteringReqFields = filteringReqFields.filter(
        (f) => f.SchemaName !== ContactEntity.street1,
      );
    } else if (address1Line1 && !postbox) {
      filteringReqFields = filteringReqFields.filter(
        (f) => f.SchemaName !== ContactEntity.poBox,
      );
    }
  }
  return filteringReqFields;
};

export function useGetBPRolesList(id, isBpDetailsFetched) {
  return useQuery({
    queryKey: ["bpRoles", { id }],
    queryFn: async ({ signal }) =>
      apiBPData.getBusinessPartnerRoles(id, signal),
    enabled: Boolean(id) && Boolean(isBpDetailsFetched),
    refetchOnMount: true,
  });
}

export function useTimelineListQuery(
  id,
  searchValue,
  page,
  func,
  debouncedSearch,
) {
  return useQuery({
    queryKey: ["timelineList", { id }, debouncedSearch, page],
    queryFn: async ({ signal }) => {
      return func(id, searchValue, page, signal);
    },
    enabled: Boolean(id),
  });
}

export function useBPRequiredFields(
  nkamStatus,
  accountType,
  legalEntityType,
  hasBillingAddress,
  isBpFetched,
) {
  return useQuery({
    queryKey: [
      "getBPRequiredFields",
      nkamStatus,
      accountType,
      legalEntityType,
      hasBillingAddress,
    ],
    queryFn: async ({ signal }) => {
      return apiBPData.getBusinessPartnerRequiredFields(
        nkamStatus,
        accountType,
        legalEntityType,
        hasBillingAddress,
        signal,
      );
    },
    enabled: Boolean(nkamStatus) && isBpFetched,
  });
}

export function useAccountTypeChanged(
  currentAccountType,
  newAccountType,
  bpStatus,
) {
  return useQuery({
    queryKey: [
      "accountTypeChanged",
      currentAccountType,
      newAccountType,
      bpStatus,
    ],
    queryFn: async ({ signal }) => {
      return apiBPData.hasDiscountCategoriesDiffer(
        currentAccountType,
        newAccountType,
        signal,
      );
    },
    enabled:
      Boolean(currentAccountType) &&
      Boolean(newAccountType) &&
      Boolean(currentAccountType !== newAccountType) &&
      Boolean(
        bpStatus === SUSPECT_VALUE ||
          bpStatus === LEAD_VALUE ||
          bpStatus === CUSTOMER_VALUE,
      ),
  });
}

export const useBPMainInitialQueries = (isBpDetailsFetched) => {
  const initialBPMainDetailsQueries = [
    {
      func: apiUIData.getCrmConfiguration,
      params: [NATIONAL_ID1_LABEL],
      key: "nationalId1Label",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NATIONAL_ID2_LABEL],
      key: "nationalId2Label",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NATIONAL_ID3_LABEL],
      key: "nationalId3Label",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [ALLOW_OWNING_KAM_EDIT],
      key: "allowOwningKamEdit",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_BusinessPartner_StreetPOBoxCheck],
      key: "streetPOBoxCheck",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [NKAM_Default_InvoiceLanguage],
      key: "defaultInvoiceLanguage",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [BusinessPartner_VatNotApplicable_Config],
      key: "vatNotApplicableConfig",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [BusinessPartner_VatNotApplicableMessage_Config],
      key: "vatNotApplicableMessage",
      enabled: isBpDetailsFetched,
    },
    {
      func: apiUIData.getCrmConfiguration,
      params: [BusinessPartner_CloneVAT_Config],
      key: "cloneVatConfig",
      enabled: isBpDetailsFetched,
    },
  ];
  return useQueries({
    queries: initialBPMainDetailsQueries.map(
      ({ func, params, key, enabled }) => ({
        queryKey: [key],
        queryFn: ({ signal }) => func(...(params || signal), signal),
        enabled: enabled,
      }),
    ),
  });
};
