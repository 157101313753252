import React, { createContext, useState } from "react";

const BusinessPartnerDetailsContext = createContext({});

const BusinessPartnerDetailsProvider = ({ children }) => {
  // Controls
  const [companyHierarchyOptions, setCompanyHierarchyOptions] = useState([]);
  const [downgradeReasonOptions, setDowngradeReasonOptions] = useState([]);
  const [nkamStatusOptions, setNkamStatusOptions] = useState([]);
  const [legalEntityTypeOptions, setLegalEntityTypeOptions] = useState([]);
  const [level2BusinessPartners, setLevel2BusinessPartners] = useState([]);
  const [owningRetailerContactOptions, setOwningRetailerContactOptions] =
    useState([]);
  const [owningRetailerOptions, setOwningRetailerOptions] = useState([]);
  const [owningKamServiceUsers, setOwningKamServiceUsers] = useState([]);
  const [salesRegionOptions, setSalesRegionOptions] = useState([]);
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [unFilteredTypeOptions, setUnFilteredTypeOptions] = useState([])
  const [
    printConfirmationTemplatesExists,
    setPrintConfirmationTemplatesExists,
  ] = useState(false);
  const [showTimeline, setShowTimeline] = useState(undefined);
  const [invoiceLanguageOptions, setInvoiceLanguageOptions] = useState([]);
  return (
    <BusinessPartnerDetailsContext.Provider
      value={{
        companyHierarchyOptions,
        setCompanyHierarchyOptions,
        downgradeReasonOptions,
        setDowngradeReasonOptions,
        nkamStatusOptions,
        setNkamStatusOptions,
        legalEntityTypeOptions,
        setLegalEntityTypeOptions,
        level2BusinessPartners,
        setLevel2BusinessPartners,
        owningRetailerContactOptions,
        setOwningRetailerContactOptions,
        owningRetailerOptions,
        setOwningRetailerOptions,
        owningKamServiceUsers,
        setOwningKamServiceUsers,
        salesRegionOptions,
        setSalesRegionOptions,
        subTypeOptions,
        setSubTypeOptions,
        typeOptions,
        setTypeOptions,
        printConfirmationTemplatesExists,
        setPrintConfirmationTemplatesExists,
        showTimeline,
        setShowTimeline,
        invoiceLanguageOptions,
        setInvoiceLanguageOptions,
        unFilteredTypeOptions,
        setUnFilteredTypeOptions
      }}
    >
      {children}
    </BusinessPartnerDetailsContext.Provider>
  );
};

export { BusinessPartnerDetailsContext, BusinessPartnerDetailsProvider };
