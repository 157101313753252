import React from "react";

import { styled } from '@mui/material/styles';

import Button from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Dialog = ({
  children,
  handler,
  handleClose,
  id,
  open,
  prompt,
  title,
  confirmationButtonText,
  showDivider = true
}) => {

  const { t } = useTranslation();

  if (confirmationButtonText === undefined) {
    confirmationButtonText = t("formActions.confirm");
  }

  return (
    <MuiDialog
      aria-labelledby={id}
      fullWidth={true}
      onClose={handleClose}
      open={open}
      PaperProps={{ elevation: 1 }}
    >
      <StyledMuiDialogTitle id={id}>
        <StyledTitleTypography component="p" variant="h4">
          {title}
        </StyledTitleTypography>

        <IconButton aria-label="Close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </StyledMuiDialogTitle>

      {prompt && (
        <StyledSubtitleTypography component="p" variant="body2">
          {prompt}
        </StyledSubtitleTypography>
      )}
      <StyledContent>
        {children}
        {showDivider && <StyledDivider />}
      </StyledContent>
      <StyledMuiDialogActions>
        <Button sx={{ width: 120 }} color="secondary" onClick={handleClose}>
          {t("formActions.cancel")}
        </Button>

        <Button
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          sx={{ width: 120 }}
          onClick={handler}
          color="primary"
          type="button"
        >
          {confirmationButtonText}
        </Button>
      </StyledMuiDialogActions>
    </MuiDialog>
  );
};
const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  height: 60,
  justifyContent: "space-between",
  padding: theme.spacing(2),
}));
const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
const StyledSubtitleTypography = styled(Typography)(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.common.black,
  color: theme.palette.common.white,
  display: "flex",
  padding: theme.spacing(1, 2),
  height: 40,
}));
const StyledContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledMuiDialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 2, 4),
}));

export default Dialog;
