import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import ContractStatus from "@/components/ContractStatus";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import InstructionalTooltip from "@/components/InstructionalTooltip";
import { CUSTOMER, LEAD, LEVEL2, PANEL_PAGE_SIZE } from "@/constants/constants";
import { contractRoute, createContractRoute } from "@/constants/routes";
import apiContractsData from "@/services/apiContracts";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledAddButton,
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTd,
  StyledTooltipWrapper,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const Contracts = ({
  bpid,
  bpStatus,
  canMarketCreate,
  companyHierarchy,
  name,
  permissions,
  primaryContacts,
  bpContactHasSignatory,
  isBpDetailsFetched,
  isFleetContractAllowed,
}) => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [contracts, setContracts] = useState([]);
  const [permissionToCreate, setPermissionToCreate] = useState(false);
  const [retailerGroupIsOwningRetailer, setRetailerGroupIsOwningRetailer] =
    useState();
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});
  const [createContractTooltip, setCreateContractTooltip] = useState(false);
  const [createContractTooltipValues, setCreateContractTooltipValues] =
    useState([]);

  const headings = [
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.contractId"), isAction: false },
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.salesModel"), isAction: false },
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.status"), isAction: false },
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.startDate"), isAction: false },
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.endDate"), isAction: false },
    { heading: t("businessPartnerDetailPage.contractsPanel.tableHeadings.action"), isAction: true },
  ];
  const contractsDataQuery = useQuery({
    queryKey: ["contracts", [bpid, page]],
    queryFn: async ({ signal }) => {
      return apiContractsData.searchContractsByBP(
        bpid,
        PANEL_PAGE_SIZE,
        page,
        signal,
      );
    },
    enabled: Boolean(isBpDetailsFetched),
  });

  useEffect(() => {
    if (contractsDataQuery.isError) {
      setApiError(handleApiError(contractsDataQuery.error));
    }

    if (contractsDataQuery.isSuccess) {
      setContracts(contractsDataQuery.data.data.result);
      setTotalPages(contractsDataQuery.data.data.totalPages);
    }
  }, [contractsDataQuery.dataUpdatedAt, contractsDataQuery.errorUpdatedAt]);

  useEffect(() => {
    if (permissions) {
      permissionToCreateContract();
    }
  }, [permissions, primaryContacts]);

  const permissionToCreateContract = () => {
    // only allow creation of a Contract if :
    // the market has permission to create a contract
    // AND
    //- the BP is a Lead or a Customer
    //- the BP has a PrimaryContact
    //- the PrimaryContact is a Recipient AND a signatory
    const { owningRetailer, retailerGroupIsOwningRetailer } = permissions;
    let permissionToCreate = false;

    if (
      (owningRetailer || retailerGroupIsOwningRetailer) &&
      canMarketCreate &&
      companyHierarchy === LEVEL2 &&
      (bpStatus?.name === LEAD || bpStatus?.name === CUSTOMER) &&
      primaryContacts.length > 0 &&
      bpContactHasSignatory &&
      isFleetContractAllowed
    ) {
      permissionToCreate = true;
    }

    setPermissionToCreate(permissionToCreate);
  };

  useEffect(() => {
    if (permissionToCreate === false) {
      setCreateContractTooltipValues([
        {
          bool:
            bpStatus?.name === LEAD || bpStatus?.name === CUSTOMER
              ? true
              : false,
          content: t(
            "businessPartnerDetailPage.contractsPanel.createContractTooltipValues.bpInLeadOrCustomer",
          ),
        },
        {
          bool: primaryContacts.length > 0 ? true : false,
          content: t(
            "businessPartnerDetailPage.contractsPanel.createContractTooltipValues.bpHasPrimaryContact",
          ),
        },
        {
          bool: bpContactHasSignatory === true ? true : false,
          content: t(
            "businessPartnerDetailPage.contractsPanel.createContractTooltipValues.bpContactHasSignatory",
          ),
        },
        {
          bool: isFleetContractAllowed === true ? true : false,
          content: t(
            "businessPartnerDetailPage.contractsPanel.createContractTooltipValues.bpFleetContractAllowed",
          ),
        },
      ]);
    }
  }, [
    permissionToCreate,
    companyHierarchy,
    bpStatus,
    bpContactHasSignatory,
    primaryContacts,
  ]);

  useEffect(() => {
    const { retailerGroupIsOwningRetailer } = permissions;

    // Set this in state so we can use it in the render
    setRetailerGroupIsOwningRetailer(retailerGroupIsOwningRetailer);

    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);

  return (
    <>
      <StyledContainer id="contracts">
        <StyledHeader>
          <StyledContents>
            <StyledHeading component="h2" variant="h4">
              {t("businessPartnerDetailPage.contractsPanel.sectionTitle")}
            </StyledHeading>
          </StyledContents>

          <Box
            position="relative"
            onMouseEnter={() =>
              !permissionToCreate &&
              createContractTooltipValues.length > 0 &&
              retailerGroupIsOwningRetailer &&
              setCreateContractTooltip(true)
            }
            onMouseLeave={() => setCreateContractTooltip(false)}
          >
            <StyledAddButton
              component={Link}
              disabled={!permissionToCreate}
              state={{
                account: {
                  id: bpid,
                  logicalName: "account",
                  name: name,
                },
              }}
              to={createContractRoute}
              title={t(
                "businessPartnerDetailPage.contractPanel.addButtonTitle",
              )}
              variant="contained"
            >
              <AddIcon />
            </StyledAddButton>
            {createContractTooltip && (
              <StyledTooltipWrapper>
                <InstructionalTooltip values={createContractTooltipValues} />
              </StyledTooltipWrapper>
            )}
          </Box>
        </StyledHeader>

        <TableHeaders headings={headings}>
          {contractsDataQuery.isFetching && (
            <StyledTbody>
              {contractsDataQuery.isFetching && <Loader />}
            </StyledTbody>
          )}
          {contracts.length > 0 ? (
            <StyledTbody>
              {contracts?.map(
                (
                  {
                    id,
                    contractCategory,
                    contractId,
                    contractStatus,
                    startDate,
                    endDate,
                  },
                  index,
                ) => (
                  <StyledTr key={index}>
                    <StyledTd>
                      <ConditionalTooltip
                        title={contractId}
                        value={contractId}
                      />
                    </StyledTd>
                    <StyledTd>
                      <ConditionalTooltip
                        title={contractCategory?.name}
                        value={contractCategory?.name}
                      />
                    </StyledTd>
                    <StyledTd>
                      <ContractStatus
                        status={contractStatus?.name}
                        value={contractStatus?.name}
                      />
                    </StyledTd>
                    <StyledTd>
                      <ConditionalTooltip
                        title={
                          startDate && convertISODateToDayMonthYear(startDate)
                        }
                        value={
                          startDate && convertISODateToDayMonthYear(startDate)
                        }
                      />
                    </StyledTd>
                    <StyledTd>
                      <ConditionalTooltip
                        title={endDate && convertISODateToDayMonthYear(endDate)}
                        value={endDate && convertISODateToDayMonthYear(endDate)}
                      />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip title={`View contract ${contractId}`}>
                        <IconButton
                          color="primary"
                          component={Link}
                          size="small"
                          state={{
                            account: {
                              id: bpid,
                              logicalName: "account",
                              name: name,
                            },
                          }}
                          to={contractRoute + id}
                        >
                          <LaunchIcon fontSize="inherit" />
                        </IconButton>
                      </ConditionalTooltip>
                    </StyledTd>
                  </StyledTr>
                ),
              )}
            </StyledTbody>
          ) : (
            <StyledNoResults>
              {t("businessPartnerDetailPage.contractsPanel.noContracts")}
            </StyledNoResults>
          )}
        </TableHeaders>

        <StyledPagination>
          {totalPages > 1 && <Paging count={totalPages} page={page} />}
        </StyledPagination>
      </StyledContainer>
      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default Contracts;
