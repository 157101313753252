import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { handleApiError } from "@/utils/handleApiError";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTable,
  StyledTableContainer,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
} from "@/components/Styles/PagesComponentsCommon";
import { useGetOrderOptionsQuery } from "@/services/queries";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import TableHeaders from "../TableHeaders";

const OrderOptions = ({ bpid }) => {
  const controller = new AbortController();
  const searchParams = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [options, setOptions] = useState([]);
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [apiError, setApiError] = useState({});

  const getOrderOptions = useGetOrderOptionsQuery(bpid, page);
  const { t } = useTranslation();
  const headings = [
    {
      heading: t("orderDetails.orderOptions.orderOptionsFields.IVS_ROption"),
      isAction: false,
    },
    {
      heading: t("orderDetails.orderOptions.orderOptionsFields.option"),
      isAction: false,
    },
  ];

  useEffect(() => {
    setIsLoading(getOrderOptions.isFetching);
    if (getOrderOptions.isError) {
      setApiError(handleApiError(getOrderOptions.error));
    }
    if (getOrderOptions.isSuccess) {
      setOptions(getOrderOptions?.data?.data?.result);
      setTotalPages(getOrderOptions?.data?.data?.totalPages);
      setReceivedResponse(true);
    }
  }, [getOrderOptions.status]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {receivedResponse && (
        <StyledContainer id="orderOptions">
          <Box>
            <StyledHeader>
              <StyledContents>
                <StyledHeading component="h2" variant="h4">
                  {t("orderDetails.orderOptions.sectionTitle")}
                </StyledHeading>
              </StyledContents>
            </StyledHeader>
          </Box>

          <TableHeaders headings={headings}>
            {options.length > 0 ? (
              <StyledTbody>
                {isLoading && <Loader />}

                {options?.map(({ ivsrOptionCode, optionName }, index) => (
                  <StyledTr key={index}>
                    <StyledTd>
                      <ConditionalTooltip title={ivsrOptionCode} />
                    </StyledTd>

                    <StyledTd>
                      <ConditionalTooltip title={optionName} />
                    </StyledTd>
                  </StyledTr>
                ))}
              </StyledTbody>
            ) : (
              <StyledNoResults>
                {t("orderDetails.orderOptions.noResults")}
              </StyledNoResults>
            )}
          </TableHeaders>

          <StyledPagination>
            {totalPages > 1 && <Paging count={totalPages} page={page} />}
          </StyledPagination>
        </StyledContainer>
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default OrderOptions;
