import apiUIData from "@/services/apiUI";
import { useTranslation } from "react-i18next";

export const getCountyList = async (countRyId) => {
  try {
    let response = await apiUIData.getCountyList(countRyId);
    return Promise.resolve(response?.data?.result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const validateCoinCheckContact = (
  contact,
  contractRolesCoinCheck,
  contactRolesErrorMsg,
  sellingNotAllowedMsg,
) => {
  const { booleanValue } = contractRolesCoinCheck?.data?.result || {};

  let errorMessage = "";
  let showFields = true;

  if (
    contact?.coinCheckPerformed &&
    contact?.coinCheckResult?.value === 174640001
  ) {
    errorMessage = sellingNotAllowedMsg?.data?.result?.stringValue;
  }

  if (booleanValue && (!contact || !contact.coinCheckPerformed)) {
    showFields = false;
    errorMessage = contactRolesErrorMsg?.data?.result?.stringValue;
  }

  return { showFields, errorMessage };
};

export const isTypeRestrictedInPortal = (bpAccType, typeOptions) => {
  let selectedType = typeOptions?.find((type) => type.id === bpAccType?.id);
  return selectedType?.restrictedDealerPortal
}
export const isFleetContractAllowed = (bpAccType, typeOptions) => {
  let selectedType = typeOptions?.find((type) => type.id === bpAccType?.id);
  return selectedType?.fleetContractAllowed
}
export const permissionToEditContact = (retailerGroupIsOwningRetailer, canMarketEditAContact, typeOptions, type) => {
  let typeRestricted = isTypeRestrictedInPortal(type, typeOptions);
  let isEditable = false;
  if (canMarketEditAContact) {
    if (retailerGroupIsOwningRetailer && !typeRestricted) {
      isEditable = true;
    }
  }

  return isEditable;
};

export const isBPFetched = (businessPartner, id) => {
  return id ? Boolean(businessPartner && Object.keys(businessPartner)?.length) : true;
};

export const useCalenderStrings = () => {
  const { t } = useTranslation();
  return {
    lastDay: `[${t("businessPartnerDetailPage.infoPanel.calendarStrings.lastDay")}] HH:mm`,
    sameDay: `[${t("businessPartnerDetailPage.infoPanel.calendarStrings.sameDay")}] HH:mm`,
    lastWeek: `dddd [${t("businessPartnerDetailPage.infoPanel.calendarStrings.lastWeek")}] HH:mm`,
    sameElse: "DD/MM/YYYY",
  };
}
