// src/components/ReusableTableContainer.jsx
import React from "react";
import {
  StyledTableContainer,
  StyledTable,
  StyledThead,
  StyledTr,
  StyledTh,
  StyledActionTh,
} from "@/components/Styles/PagesComponentsCommon"; // Adjust the import path as needed
import { Tooltip } from "@mui/material";

const TableHeaders = ({ headings, children }) => {
  return (
    <StyledTableContainer>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            {headings.map((heading, index) =>
              heading.isAction ? (
                <StyledActionTh>
                  <Tooltip title={heading.heading}>{heading.heading}</Tooltip>
                </StyledActionTh>
              ) : (
                <StyledTh key={index}><Tooltip title={heading.heading}>{heading.heading}</Tooltip></StyledTh>
              ),
            )}
          </StyledTr>
        </StyledThead>
        {children}
      </StyledTable>
    </StyledTableContainer>
  );
};

export default TableHeaders;
