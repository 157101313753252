import { convertGMTDateToISO } from "@/utils/convertGMTDateToISO";
import { LEVEL2_VALUE, PROSPECT_VALUE } from "./constants";

// FORM TYPES
export const CHECKBOX = "checkbox";
export const DATE = "date";
export const EMAIL = "email";
export const LOOKUP = "lookup";
export const NUMBER = "number";
export const SELECT = "select";
export const SWITCH = "switch";
export const TEL = "tel";
export const TEXT = "text";
export const NAME = "name";
export const CLEAR = "clear";

// FILE SIZE LIMIT
export const MAX_FILE_SIZE = 20971520;

// FORM VALIDATION
export const EMAIL_VALIDATION_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

export const TELEPHONE_VALIDATION_REGEX = /^[+|0][0-9]{0,15}$/;

export const NAME_VALIDATION_REGEX =
  /^(?!(.*\s$|\s.*)|.*(&|%|\\|\$|!|§|`|´|<|>|=|\?|\^|\(|\)|\||\/|:){1,}).*$/;

// DEFAULT VALUES
export const BP_DEFAULT_VALUES = {
  name: "",
  bmw_legalname: "",
  bmw_vatnumber: "",
  bmw_representedby: "",
  bmw_companyhierarchylevel: LEVEL2_VALUE,
  websiteurl: "",
  parentaccountid: null,
  bmw_address1buildingnumber: "",
  address1_line1: "",
  address1_line2: "",
  address1_line3: "",
  address1_postalcode: "",
  address1_postofficebox: "",
  address1_city: "",
  address1_county: null,

  bmw_address1_countyid: "",
  bmw_salesregionid: null,
  bmw_hasbillingaddress: false,
  bmw_address2buildingnumber: "",
  address2_line1: "",
  address2_line2: "",
  address2_line3: "",
  address2_postalcode: "",
  address2_postofficebox: "",
  address2_city: "",
  address2_county: null,
  address2_country: "",
  bmw_address2salesregionid: null,
  bmw_accounttypeid: null,
  bmw_accountsubtypeid: null,
  bmw_legalentitytypeid: null,
  bmw_status: PROSPECT_VALUE,
  bmw_downgradereason: "",
  bmw_downgradedate: null,
  bmw_downgradedescription: "",
  bmw_owningdealerid: null,
  bmw_owningdealercontactid: null,
  ownerid: null,
  accountnumber: "",
  bmw_nsccompanyid: "",
  bmw_nsccompanyid2: "",
  bmw_nsccompanyid3: "",
  bmw_registrationoffice: "",
  bmw_duns: "",
  bmw_totalnoofemployees2: 0,
  bmw_totalnoofactiveretailerroles: 0,
  bmw_confirmationofcorporatecustomer: false,
  bmw_employeeconfirmation: false,
  bmw_receivesmotorradterms: false,
  bmw_employeesalespermitted: false,
  bmw_leasingcompanyadmissionallowed: false,
  AccountCategoryCode: null,
  bmw_employeesalescategoryname: null,
  vatCloned: false,
};

export const CONTACT_DEFAULT_VALUES = {
  salutation: "",
  firstname: "",
  lastname: "",
  middlename: "",
  bmw_secondlastname: "",
  bmw_department: null,
  suffix: "",
  jobtitle: "",
  bmw_position2: "",
  bmw_position: "",
  parentcustomerid: null,
  bmw_contactdetailssource: "",
  bmw_tobedeletedon: null,
  emailaddress1: "",
  bmw_emailbounceback: "",
  bmw_primarylanguageid: null,
  telephone1: "",
  mobilePhone: "",
  otherPhone: "",
  bmw_contractsignatory: false,
  bmw_contractrecipient: false,
  bmw_influencer: false,
  bmw_decisionmaker: false,
  bmw_fleetmanager: false,
  bmw_authorisedrepresentative: false,
  bmw_ultimatebeneficialowner: false,
  bmw_address1buildingnumber: "",
  address1_line1: "",
  address1_line2: "",
  address1_line3: "",
  address1_city: "",
  bmw_address1_county: null,
  address1_postalcode: "",
  address1_postofficebox: "",
  bmw_country: "",
  bmw_billingcontact: false,
  bmw_partnership: false,
  bmw_salescontact: false,
  bmw_agreementcontact: false,
  bmw_keycontact: false,
  marketingonly: false,
};

export const BPROLES_DEFAULT_VALUES = {
  bmw_name: "",
  bmw_parentid: null,
  bmw_childid: null,
  bmw_type: null,
  bmw_startdate: convertGMTDateToISO(new Date()),
  bmw_enddate: "",
};

export const BusinePartnerRoleEntity = {
  name: "bmw_name",
  parentId: "bmw_parentid",
  childId: "bmw_childid",
  type: "bmw_type",
  startDate: "bmw_startdate",
  endDate: "bmw_enddate",
  roleStatus: "bmw_nkamrolestatus",
};

export const DunsSearchType = {
  DUNS: 1,
  LOOKUP: 2,
};

export const BusinePartnerEntity = {
  name: "name",
  legalName: "bmw_legalname",
  vatNumber: "bmw_vatnumber",
  representedBy: "bmw_representedby",
  companyHierarchy: "bmw_companyhierarchylevel",
  url: "websiteurl",
  l2ParentCompany: "parentaccountid",
  buildingNumber: "bmw_address1buildingnumber",
  address1Line1: "address1_line1",
  address1Line2: "address1_line2",
  address1Line3: "address1_line3",
  address1PostCode: "address1_postalcode",
  postbox: "address1_postofficebox",
  address1City: "address1_city",
  county: "address1_county",
  invoiceLanguage: "bmw_invoicelanguage",
  termOfCredit: "bmw_termofcreditid",
  ucid: "bmw_gcdmucid",
  gcdmId: "bmw_gcdmid",
  country: "bmw_country",
  salesRegion: "bmw_salesregionid",
  hasBillingAddress: "bmw_hasbillingaddress",
  address2BuildingNumber: "bmw_address2buildingnumber",
  address2Line1: "address2_line1",
  address2Line2: "address2_line2",
  address2Line3: "address2_line3",
  address2PostCode: "address2_postalcode",
  address2PostBox: "address2_postofficebox",
  address2City: "address2_city",
  address2County: "address2_county",
  address2Country: "address2_country",
  address2SalesRegion: "bmw_address2salesregionid",
  type: "bmw_accounttypeid",
  subType: "bmw_accountsubtypeid",
  legalEntityType: "bmw_legalentitytypeid",
  nkamStatus: "bmw_status",
  downgradeReason: "bmw_downgradereason",
  downgradeDate: "bmw_downgradedate",
  downgradeDescription: "bmw_downgradedescription",
  owningRetailer: "bmw_owningdealerid",
  owningRetailerContact: "bmw_owningdealercontactid",
  owningKAM: "ownerid",
  globusId: "accountnumber",
  nationalId1: "bmw_nsccompanyid",
  nationalId2: "bmw_nsccompanyid2",
  nationalId3: "bmw_nsccompanyid3",
  registrationOffice: "bmw_registrationoffice",
  dunsNumber: "bmw_duns",
  totalNumberOfEmployees: "bmw_totalnoofemployees2",
  totalNumberOfActiveRetailerRoles: "bmw_totalnoofactiveretailerroles",
  confirmedCorporateCustomer: "bmw_confirmationofcorporatecustomer",
  employeeConfirmation: "bmw_employeeconfirmation",
  receivesMotorradTerms: "bmw_receivesmotorradterms",
  employeeSalesPermitted: "bmw_employeesalespermitted",
  leasingCompanyAdmissionAllowed: "bmw_leasingcompanyadmissionallowed",
  businessCategory: "AccountCategoryCode",
  employeeSalesCategory: "bmw_employeesalescategoryname",
  expectedVolume: "bmw_expectedvolume",
  vatCloned: "vatCloned",
  mainPhone: "telephone1",
  fleetSize: "bmw_fleetsize",
  vatNotApplicable: "bmw_vatnotapplicable",
};

export const ContactEntity = {
  title: "salutation",
  firstName: "firstname",
  lastName: "lastname",
  middleName: "middlename",
  secondLastName: "bmw_secondlastname",
  department: "bmw_department",
  suffix: "suffix",
  jobTitle: "jobtitle",
  jobFunction: "bmw_position2",
  position: "bmw_position",
  parentCustomer: "parentcustomerid",
  dataSource: "bmw_contactdetailssource",
  toBeDeletedOn: "bmw_tobedeletedon",
  email: "emailaddress1",
  emailBounceBack: "bmw_emailbounceback",
  primaryLanguage: "bmw_primarylanguageid",
  businessPhone: "telephone1",
  mobilePhone: "mobilephone",
  otherPhone: "",
  dataConsentAvailable: "bmw_optinavailable",
  politicallyExposedPerson: "bmw_politicallyexposedperson",
  signatory: "bmw_contractsignatory",
  recipient: "bmw_contractrecipient",
  influencer: "bmw_influencer",
  decisionMaker: "bmw_decisionmaker",
  fleetManager: "bmw_fleetmanager",
  authorisedRepresentative: "bmw_authorisedrepresentative",
  ultimateBeneficialOwner: "bmw_ultimatebeneficialowner",
  buildingNumber: "bmw_address1buildingnumber",
  street1: "address1_line1",
  street2: "address1_line2",
  street3: "address1_line3",
  city: "address1_city",
  county: "bmw_address1_county",
  postalCode: "address1_postalcode",
  poBox: "address1_postofficebox",
  country: "bmw_country",
  billing: "bmw_billingcontact",
  partnership: "bmw_partnership",
  sales: "bmw_salescontact",
  agreement: "bmw_agreementcontact",
  keyContact: "bmw_keycontact",
  marketing: "marketingonly",
  ucid: "bmw_gcdmucid",
  gcdmId: "bmw_gcdmid",
};

export const ContractEntity = {
  salesChannel: "bmw_saleschannel",
  contractCategory: "bmw_contractcategoryid",
  template: "bmw_documenttemplate",
  contractStatus: "bmw_contractstatusglobaloptionset",
  customised: "bmw_exoticcontract",
  startDate: "bmw_startdate",
  endDate: "bmw_endofcontract",
  duration: "bmw_duration",
  upfrontDiscount: "bmw_contracttemplateid",
  contractAnnotation: "bmw_terms_and_conditions",
  dateSigned: "bmw_datesigned",
  lastDateSubmitClaim: "bmw_datetosubmitclaimdocuments",
  signedByNSC: "bmw_signedbynsc",
  lastCompletedOrder: "bmw_lastorderdate",
  lastAgreementDate: "bmw_lastagreementdate",
  totalCompletedOrders: "bmw_totalcompletedorders",
  ownVehicleOrders: "bmw_bulkapprovalteam",
  leasedVehicleOrders: "bmw_leasedvehicleorders",
  businessPartner: "bmw_accountid",
  owner: "ownerid",
};

export const CONTRACT_DEFAULT_VALUES = {
  bmw_accountid: "",
  bmw_saleschannel: "",
  bmw_contractstatusglobaloptionset: "",
  bmw_contractcategoryid: null,
  bmw_exoticcontract: 10,
  bmw_signedbynsc: 10,
  bmw_documenttemplate: null,
  ownerid: "",
  bmw_startdate: convertGMTDateToISO(new Date()),
  bmw_endofcontract: convertGMTDateToISO(
    new Date(new Date().getFullYear(), 11, 31),
  ),
  bmw_duration: 0,
  bmw_contracttemplateid: null,
  bmw_terms_and_conditions: "",
  bmw_datesigned: null,
  bmw_datetosubmitclaimdocuments: null,
  bmw_lastorderdate: null,
  bmw_lastagreementdate: null,
  bmw_totalcompletedorders: 0,
  bmw_bulkapprovalteam: 0,
  bmw_leasedvehicleorders: 0,
};
