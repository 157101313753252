import React, { useContext, useEffect, useState } from "react";
import { Link, createSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { LEVEL2 } from "@/constants/constants";
import { contactRoute, createContactRoute } from "@/constants/routes";
import {
  StyledAddButton,
  StyledContents,
  StyledDescription,
  StyledHeader,
  StyledHeading,
  StyledPanelPaper,
  StyledPanelDataTable,
  StyledPanelTable,
  StyledPanelThead,
  StyledPanelTh,
  StyledPanelTableBody,
  StyledPanelTableTitleRow,
  StyledPanelMessageRow,
  StyledPanelTableRow
} from "@/components/Styles/PagesComponentsCommon";
import { isTypeRestrictedInPortal } from "@/helperFunctions/generic";
import { BusinessPartnerDetailsContext } from "@/context/BusinessPartnerDetailsContext"
import { useTranslation } from "react-i18next";
import Loader from "../Loader";

const ContactsPanel = ({
  bpid,
  companyHierarchy,
  name,
  nonPrimaryContacts,
  permissions,
  primaryContacts,
  canMarketCreateContacts,
  showPrimaryContacts,
  showNonPrimaryContacts,
  bpMainaddress,
  bpType,
  isContactsLoading
}) => {

  const { t } = useTranslation();
  const { unFilteredTypeOptions } = useContext(BusinessPartnerDetailsContext);

  const [permissionToCreate, setPermissionToCreate] = useState(false);

  useEffect(() => {
    if (permissions) {
      permissionToAddContact();
    }
  }, [permissions]);

  const permissionToAddContact = () => {
    const { retailerGroupIsOwningRetailer } = permissions;

    if (canMarketCreateContacts) {
      if (retailerGroupIsOwningRetailer && companyHierarchy === LEVEL2 && !isTypeRestrictedInPortal(bpType, unFilteredTypeOptions)) {
        setPermissionToCreate(true);
      }
    } else {
      setPermissionToCreate(false);
    }
  };

  return (
    <StyledPanelPaper
      id="contacts"
      elevation={0}
      style={{ marginTop: "30px" }}
    >
      <Box>
        <StyledHeader>
          <StyledContents>
            <StyledHeading component="h2" variant="h4">
              {t("businessPartnerDetailPage.contactsPanel.sectionTitle")}
            </StyledHeading>

            <StyledDescription
              component="span"
              variant="body2"
            >
              {t("businessPartnerDetailPage.contactsPanel.description")}
            </StyledDescription>
          </StyledContents>

          <StyledAddButton
            component={Link}
            disabled={!permissionToCreate}
            state={{
              account: {
                id: bpid,
                logicalName: "account",
                name: name,
                bpMainaddress: bpMainaddress
              },
            }}
            to={createContactRoute}
            title={t("businessPartnerDetailPage.contactsPanel.createAContact")}
          >
            <AddIcon />
          </StyledAddButton>
        </StyledHeader>
      </Box>

      <Grid item xs={12}>
        <StyledPanelDataTable>
          <StyledPanelTable aria-label={t("businessPartnerDetailPage.contactsPanel.contactsTable.headingAria")}>
            <StyledPanelThead>
              <TableRow>
                <StyledPanelTh width={50}>
                  <Box component={"span"} paddingLeft={1}>
                    {t("businessPartnerDetailPage.contactsPanel.contactsTable.salutation")}
                  </Box>
                </StyledPanelTh>
                <StyledPanelTh>
                  {t("businessPartnerDetailPage.contactsPanel.contactsTable.fullName")}
                </StyledPanelTh>
                <StyledPanelTh>
                  {t("businessPartnerDetailPage.contactsPanel.contactsTable.jobTitle")}
                </StyledPanelTh>
                <StyledPanelTh>
                  {t("businessPartnerDetailPage.contactsPanel.contactsTable.jobFunction")}
                </StyledPanelTh>
                <StyledPanelTh>
                  {t("businessPartnerDetailPage.contactsPanel.contactsTable.action")}
                </StyledPanelTh>
              </TableRow>
            </StyledPanelThead>

            <StyledPanelTableBody>
              {isContactsLoading && <Loader />}
              {showPrimaryContacts && (
                <>
                  <StyledPanelTableTitleRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                      colSpan={5}
                      scope="row"
                    >
                      <Box
                        component={"span"}
                        paddingLeft={1}
                        paddingBottom={0}
                        color="#2a336f"
                      >
                        {t("businessPartnerDetailPage.contactsPanel.contactsTable.primaryContact")}
                      </Box>
                    </TableCell>
                  </StyledPanelTableTitleRow>

                  {primaryContacts &&
                    primaryContacts?.map((contact) => {
                      return (
                        <StyledPanelTableTitleRow
                          key={contact.id}
                        >
                          <TableCell
                            width={50}
                            style={{ padding: "4px 16px 10px 16px" }}
                          >
                            <Box component={"span"} paddingLeft={1}>
                              {contact.title}
                            </Box>
                          </TableCell>
                          <TableCell style={{ padding: "4px 16px 10px 16px" }}>
                            {contact.fullName}
                          </TableCell>
                          <TableCell style={{ padding: "4px 16px 10px 16px" }}>
                            {contact.jobTitle}
                          </TableCell>
                          <TableCell style={{ padding: "4px 16px 10px 16px" }}>
                            {contact.jobFunction?.name}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              component={Link}
                              to={{
                                pathname: contactRoute + contact.id,
                                search: `${createSearchParams({ bpid: bpid, isPrimaryContact: true })}`,
                              }}
                              size="small"
                              state={{
                                account: {
                                  id: bpid,
                                  logicalName: "account",
                                  name: name,
                                },
                                isPrimaryContact: true,
                              }}
                              title={t("businessPartnerDetailPage.contactsPanel.contactsTable.viewPrimaryTitle", { contactFullName: contact.fullName })}
                            >
                              <LaunchIcon fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                        </StyledPanelTableTitleRow>
                      );
                    })}

                  {primaryContacts?.length === 0 && (
                    <StyledPanelTableTitleRow>
                      <StyledPanelMessageRow
                        colSpan={5}
                      >
                        {t("businessPartnerDetailPage.contactsPanel.contactsTable.noPrimaryContact")}
                      </StyledPanelMessageRow>
                    </StyledPanelTableTitleRow>
                  )}
                </>
              )}

              {showNonPrimaryContacts && (
                <>
                  <StyledPanelTableTitleRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                      colSpan={5}
                      scope="row"
                    >
                      <Box component={"span"} paddingLeft={1}>
                        {t("businessPartnerDetailPage.contactsPanel.contactsTable.nonPrimaryContacts")}
                      </Box>
                    </TableCell>
                  </StyledPanelTableTitleRow>
                  {nonPrimaryContacts &&
                    nonPrimaryContacts?.map((contact) => {
                      return (
                        <StyledPanelTableRow
                          key={contact.id}
                        >
                          <TableCell width={50}>
                            <Box component={"span"} paddingLeft={1}>
                              {contact.title}
                            </Box>
                          </TableCell>
                          <TableCell>{contact.fullName}</TableCell>
                          <TableCell>{contact.jobTitle}</TableCell>
                          <TableCell>{contact.jobFunction?.name}</TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              component={Link}
                              to={{
                                pathname: contactRoute + contact.id,
                                search: `${createSearchParams({ bpid: bpid, isPrimaryContact: true })}`,
                              }}
                              size="small"
                              state={{
                                account: {
                                  id: bpid,
                                  logicalName: "account",
                                  name: name,
                                },
                                isPrimaryContact: false,
                              }}
                              title={t("businessPartnerDetailPage.contactsPanel.contactsTable.viewNonPrimaryTitle", { contactFullName: contact.fullName })}
                            >
                              <LaunchIcon fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                        </StyledPanelTableRow>
                      );
                    })}

                  {nonPrimaryContacts?.length === 0 && (
                    <StyledPanelTableTitleRow>
                      <StyledPanelMessageRow
                        colSpan={5}
                      >{t("businessPartnerDetailPage.contactsPanel.contactsTable.noNonPrimaryContacts")}
                      </StyledPanelMessageRow>
                    </StyledPanelTableTitleRow>
                  )}
                </>
              )}
            </StyledPanelTableBody>
          </StyledPanelTable>
        </StyledPanelDataTable>
      </Grid>
    </StyledPanelPaper>
  );
};


export default ContactsPanel;
