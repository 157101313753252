import { userKey } from "@/constants/constants";
import utils from "@/utils/common";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const { marketCode } = utils.secureGetFromLocalStorage(userKey) || {
  marketCode: "en",
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: marketCode.toLowerCase(),
    fallbackLng: {
      at: ["de"],
      default: ["en"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/i18n/{{lng}}.json`,
    },
  });

export default i18n;
