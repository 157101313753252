import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import AppError from "@/AppError";
import CustomControl from "@/components/Form/CustomControl";
import Section from "@/components/Section";
import SectionTitle from "@/components/SectionTitle";
import { ContractEntity } from "@/constants/forms";

import {
  CREATE,
  NO,
  YES,
  WARNING,
  NOT_TAKEN_UP_VALUE,
  CONTRACT_AUTHORISED_VALUE,
  ACTIVE_VALUE,
  CONTRACT_EXPIRed_VALUE,
  CONTRACT_TERMINATED_VALUE,
} from "@/constants/constants";
import { DATE, LOOKUP, NUMBER, SELECT, TEXT } from "@/constants/forms";
import { businessPartnerRoute } from "@/constants/routes";
import { ContractDetailsContext } from "@/context/ContractDetailsContext";
import { handleApiError } from "@/utils/handleApiError";
import { AppContext } from "@/context/AppContext";
import { useContRactCoinCheck, useContractTemplateOptions, useDiscountMatrixFilteredDataQueries } from "@/services/queries";
import { useTranslation } from "react-i18next";

const ContractMainDetails = ({
  contract,
  control,
  disabled,
  filteredStatuses,
  mode,
  resetField,
  watch,
  setShowAlert,
  setCanSave,
  setLoader,
}) => {
  const {
    contractCategories,
    salesChannels, signedByNSCOptions,
    int
  } = useContext(ContractDetailsContext);
  const location = useLocation();
  const bpId = location?.state?.account?.id || contract.account.id;
  const { t } = useTranslation();
  const { setAlertStatus, setAlertMessage } = useContext(AppContext);
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [dmOptions, setDmOptions] = useState([]);
  const isLoading = false;
  const [apiError, setApiError] = useState({});

  const salesChannelId = watch(ContractEntity.salesChannel);
  const salesModel = watch(ContractEntity.contractCategory);
  const contractStatus = watch(ContractEntity.contractStatus);
  const getContractTemplateOptions = useContractTemplateOptions(salesChannelId);
  const coinCheckQuery = useContRactCoinCheck(bpId, salesModel);
  const returnedDiscountMatrixFilteredData = useDiscountMatrixFilteredDataQueries(salesModel?.name, bpId);

  const isContractStatusDisable =
    contractStatus === NOT_TAKEN_UP_VALUE ||
    contractStatus === CONTRACT_AUTHORISED_VALUE ||
    contractStatus === ACTIVE_VALUE ||
    contractStatus === CONTRACT_EXPIRed_VALUE ||
    contractStatus === CONTRACT_TERMINATED_VALUE;

  // Separating Sales channel Id change logic and Sales modal change logic as it's not dependent each other and Making an unnecessary expensive API call when not required.
  useEffect(() => {
    if (!salesChannelId) {
      setDocumentTemplates([]);
    }
    resetField("template");
  }, [salesChannelId]);

  useEffect(() => {
    if (getContractTemplateOptions.isError) {
      setApiError(handleApiError(getContractTemplateOptions.error));
    }
    if (getContractTemplateOptions.isSuccess) {
      setDocumentTemplates(getContractTemplateOptions.data?.data?.result || []);
    }
  }, [getContractTemplateOptions.status]);

  useEffect(() => {
    setLoader(coinCheckQuery.isFetching);
    if (coinCheckQuery.isFetching) return;
    if (coinCheckQuery.isError) {
      setApiError(handleApiError(coinCheckQuery.error));
    }
    if (coinCheckQuery.isSuccess) {
      setCanSave(coinCheckQuery.data.canSave);
      if (coinCheckQuery.data.alertMessage != undefined && Boolean(coinCheckQuery?.data?.alertMessage)) {
        setAlertStatus(WARNING);
        setAlertMessage(coinCheckQuery.data.alertMessage);
        setShowAlert(true);
      }
    }
  }, [coinCheckQuery.dataUpdatedAt, coinCheckQuery.errorUpdatedAt]);

  useEffect(() => {
    if (returnedDiscountMatrixFilteredData?.error != null) {
      setApiError(handleApiError(returnedDiscountMatrixFilteredData?.error));
    }

    let filteredDms;
    if (returnedDiscountMatrixFilteredData?.data?.data?.result) {
      filteredDms = returnedDiscountMatrixFilteredData.data.data.result;
      setDmOptions(filteredDms || []);
    }
    else {
      setDmOptions([]);
    }
  }, [returnedDiscountMatrixFilteredData.dataUpdatedAt, returnedDiscountMatrixFilteredData.errorUpdatedAt]);

  useEffect(() => {
    if (salesModel?.name !== undefined && salesModel?.name !== null && salesModel.name.toLowerCase() !== "direct") {
      setCanSave(true);
    }
  }, [salesModel]);

  useEffect(() => {
    return () => {
      // controller.abort();
    };
  }, []);

  return (
    <>
      <Section id="details">
        <SectionTitle category={true} title={t("contractDetailsPage.mainDetails.sectionTitle")} />
      </Section>
      <Section>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled
              label={t("contractDetailsPage.mainDetails.mainDetailsFields.businessPartner")}
              mode={mode}
              name={ContractEntity.businessPartner}
              required
              type={TEXT}
              url={businessPartnerRoute + contract?.account?.id}
              view={contract?.account?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            {salesChannels.length > 0 && (
              <CustomControl
                control={control}
                disabled={disabled}
                label={t("contractDetailsPage.mainDetails.mainDetailsFields.salesChannel")}
                mode={mode}
                name={ContractEntity.salesChannel}
                options={salesChannels}
                type={SELECT}
                view={contract?.salesChannel?.name}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            {contractCategories.length > 0 && (
              <CustomControl
                control={control}
                disabled={disabled}
                label={t("contractDetailsPage.mainDetails.mainDetailsFields.salesModel")}
                mode={mode}
                name={ContractEntity.contractCategory}
                options={contractCategories}
                required
                type={LOOKUP}
                view={contract?.contractCategory?.name}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={disabled || isLoading || documentTemplates.length === 0}
              label={t("contractDetailsPage.mainDetails.mainDetailsFields.documentTemplate")}
              mode={mode}
              name={ContractEntity.template}
              options={documentTemplates}
              type={LOOKUP}
              view={contract?.template?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("contractDetailsPage.mainDetails.mainDetailsFields.contractStatus")}
              mode={mode}
              disabled={isContractStatusDisable}
              name={ContractEntity.contractStatus}
              options={filteredStatuses}
              required
              type={SELECT}
              view={contract?.contractStatus?.name}
            />
          </Grid>



          {mode !== CREATE && (
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={true}
                label={t("contractDetailsPage.mainDetails.mainDetailsFields.owner")}
                mode={mode}
                name={ContractEntity.owner}
                required
                type={TEXT}
                view={contract?.owner?.name}
              />
            </Grid>
          )}
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("contractDetailsPage.mainDetails.datesSectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={disabled}
              label={t("contractDetailsPage.mainDetails.datesSectionFields.startDate")}
              mode={mode}
              name={ContractEntity.startDate}
              required
              type={DATE}
              view={contract?.startDate}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={disabled}
              label={t("contractDetailsPage.mainDetails.datesSectionFields.endDate")}
              mode={mode}
              name={ContractEntity.endDate}
              required
              type={DATE}
              view={contract?.endDate}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={disabled}
              label={t("contractDetailsPage.mainDetails.datesSectionFields.duration")}
              mode={mode}
              name={ContractEntity.duration}
              type={TEXT}
              view={contract?.days}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title={t("contractDetailsPage.mainDetails.tandcSectionTitle")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={disabled}
              label={t("contractDetailsPage.mainDetails.tandcSectionFields.upfrontDiscountMatrix")}
              mode={mode}
              name={ContractEntity.upfrontDiscount}
              options={dmOptions}
              required={mode !== CREATE}
              type={LOOKUP}
              view={contract?.upfrontDiscount?.name}
              url={
                contract?.upfrontDiscount?.id
                  ? `/discount-matrix/${contract?.upfrontDiscount?.id}`
                  : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <CustomControl
              control={control}
              label={t("contractDetailsPage.mainDetails.tandcSectionFields.contractAnnotation")}
              minRows={9}
              mode={mode}
              multiline={true}
              name={ContractEntity.contractAnnotation}
              type={TEXT}
              view={contract?.termsConditions}
            />
          </Grid>
        </Grid>
      </Section>

      {mode !== CREATE && (
        <>
          <Section>
            <SectionTitle title={t("contractDetailsPage.mainDetails.moreDetailsSectionTitle")} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  label={t("contractDetailsPage.mainDetails.moreDetailsSectionFields.dateSigned")}
                  mode={mode}
                  name={ContractEntity.dateSigned}
                  type={DATE}
                  view={contract?.dateSigned}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={disabled}
                  label={t("contractDetailsPage.mainDetails.moreDetailsSectionFields.lastDateToSubmit")}
                  mode={mode}
                  name={ContractEntity.lastDateSubmitClaim}
                  type={DATE}
                  view={contract?.lastDateSubmitClaim}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  label={t("contractDetailsPage.mainDetails.moreDetailsSectionFields.signedByNSC")}
                  mode={mode}
                  name={ContractEntity.signedByNSC}
                  options={signedByNSCOptions}
                  showPlaceholderOption={false}
                  type={SELECT}
                  view={contract?.signedByNSC ? YES : NO}
                />
              </Grid>
            </Grid>
          </Section>

          <Section>
            <SectionTitle title={t("contractDetailsPage.mainDetails.contractKPIsSectionTitle")} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={true}
                  label={t("contractDetailsPage.mainDetails.contractKPIsSectionFields.lastCompletedOrder")}
                  mode={mode}
                  name={ContractEntity.lastCompletedOrder}
                  type={DATE}
                  view={contract?.lastCompletedOrder}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={true}
                  label={t("contractDetailsPage.mainDetails.contractKPIsSectionFields.lastAggrementDate")}
                  mode={mode}
                  name={ContractEntity.lastAgreementDate}
                  type={DATE}
                  view={contract?.lastAgreementDate}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={true}
                  label={t("contractDetailsPage.mainDetails.contractKPIsSectionFields.totalCompletedOrders")}
                  mode={mode}
                  name={ContractEntity.totalCompletedOrders}
                  type={NUMBER}
                  view={contract?.totalCompletedOrders}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={true}
                  label={t("contractDetailsPage.mainDetails.contractKPIsSectionFields.ownVehicleOrders")}
                  mode={mode}
                  name={ContractEntity.ownVehicleOrders}
                  type={NUMBER}
                  view={contract?.ownVehicleOrders}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={true}
                  label={t("contractDetailsPage.mainDetails.contractKPIsSectionFields.leasedVehicleOrders")}
                  mode={mode}
                  name={ContractEntity.leasedVehicleOrders}
                  type={NUMBER}
                  view={contract?.leasedVehicleOrders}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

export default ContractMainDetails;
