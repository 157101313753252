import React, { createContext, useRef, useState } from "react";

const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [alertStatus, setAlertStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [breadcrumbLevel3Text, setBreadcrumbLevel3Text] = useState("");
  const [primaryLanguageOptions, setPrimaryLanguageOptions] = useState([]); // Primary Language Options is being used in BP Details and Contact Details pages. So it's wise to provide this from APP rather than having separate state in BP and Contact contexts
  const [countryOptions, setCountryOptions] = useState([]);
  const [userData, setUserData] = useState({});
  const headerRef = useRef(null);
  const mainRef = useRef(null);

  const controller = new AbortController();

  return (
    <AppContext.Provider
      value={{
        alertStatus,
        setAlertStatus,
        alertMessage,
        setAlertMessage,
        breadcrumbLevel3Text,
        setBreadcrumbLevel3Text,
        primaryLanguageOptions,
        setPrimaryLanguageOptions,
        countryOptions,
        setCountryOptions,
        userData,
        setUserData,
        headerRef,
        mainRef,
        controller,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
