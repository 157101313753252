import React from "react";

import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import MuiModal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const Modal = ({
  buttonText = "Close",
  handler,
  message,
  open,
  responseURL,
  statusCode,
  title,
}) => {

  return (
    <StyledModal open={open}>
      <Paper>
        <StatusCode component="h2" variant="h1">
          {statusCode}
        </StatusCode>

        <Title component="h3" variant="h3">
          {title}
        </Title>

        {message && (
          <Typography component="p" variant="body2">
            {message}
          </Typography>
        )}

        {responseURL && (
          <Typography component="p" variant="body2">
            {responseURL}
          </Typography>
        )}

        {handler && (
          <StyledButton onClick={handler}>
            {buttonText}
          </StyledButton>
        )}
      </Paper>
    </StyledModal>
  );
};

const StyledModal = styled(MuiModal)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Paper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: 3,
  boxShadow: theme.shadows[0],
  outline: "none",
  overflowY: "auto",
  padding: theme.spacing(3, 2),
  maxHeight: "100%",
  maxWidth: 520,
  textAlign: "center",
  width: "100%",
}));
const StatusCode = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(1),
}));
const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  minWidth: 180,
}))

export default Modal;
