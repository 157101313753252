import { apiQuery } from "./apiHandler";
import utils from "../utils/common";
import { DEFAULT_LANGUAGE, languageCode, userKey } from "@/constants/constants";

const getApprovalButtonVisibility = (id, entity, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "ui/approvalvisibility/" + id + "?entitySchemaName=" + entity;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getApprovalTypes = (entity, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/approvaltypes?entity=" + entity;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getCompanyHierarchyOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/bpcompanyhierarchyoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getContractCategoryOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "contractcategory";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getContractStatusOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/contractstatusoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getContractTemplateOptions = (salesChannelId, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "contracts/documenttemplates/" + salesChannelId;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getCountryOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/countryoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getDepartmentOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/departmentoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getDowngradeReasonOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/downgradereasonoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getEmailBounceBackOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/emailbouncebackoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getJobFunctionOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/jobfunctionoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getLegalEntityTypeOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/legalentitytypeoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getNkamStatusOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/nkamstatusoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOwningRetailerContactOptions = (retailerID, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/bpowningretailercontactoptions/" + retailerID;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getLevel2BusinessPartners = (searchText, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/l2?name=" + utils.autoWildcardSearchTerm(searchText);
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getOwningRetailerOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/owningretaileroptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getPositionOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/positionoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getPrimaryLanguageOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/primarylanguageoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getPrintConfirmationTemplatesByLanguage = (
  languageId,
  controller = null,
) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/printconfirmationtemplates/" + languageId;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getPrintConfirmationTemplatesExists = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/printconfirmationtemplatesexist";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getSalesChannelOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/saleschanneloptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getSalesRegionOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/salesregionoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

  const getSubTypeOptions = (id = null,controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/bpsubtypeoptions/"+id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });


const getTypeOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/bptypeoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getUpfrontDiscountMatrixOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "discountmatrix";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getFilteredUpfrontDiscountMatrixOption = (salesModelType, businessPartnerId, controller = null) =>
new Promise((resolve, reject) => {
    let buildingEndpoint = `discountmatrix/data?salesmodeltype=${salesModelType}&businesspartnerid=`;
    if(businessPartnerId && businessPartnerId !== undefined){
      buildingEndpoint = `${buildingEndpoint}${businessPartnerId}`;
    }
    const endpoint = buildingEndpoint;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const sendForApproval = (id, entity, approvalSetupId, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint =
      "ui/sendforapproval/" +
      id +
      "?entitySchemaName=" +
      entity +
      "&approvalSetupId=" +
      approvalSetupId;

    apiQuery(endpoint, controller, "PATCH", resolve, reject);
  });

const getDunsMarketConfig = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "user/DunsMarket";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getCrmConfiguration = (config, controller = null) =>{
  let language = utils.secureGetFromLocalStorage(userKey)[languageCode] || DEFAULT_LANGUAGE;
  return new Promise((resolve, reject) => {
    const endpoint = "user/configuration?configName=" + config+"&language="+language;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });
}

const getCountyList = (id, controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/county?countrycode=" + id;
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getInvoiceLanguageOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/invoiceLanguageOptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });

const getBPRoleTypeOptions = (controller = null) =>
  new Promise((resolve, reject) => {
    const endpoint = "ui/bproletypeoptions";
    apiQuery(endpoint, controller, "GET", resolve, reject);
  });
const apiUIData = {
  getApprovalButtonVisibility,
  getApprovalTypes,
  getCompanyHierarchyOptions,
  getContractCategoryOptions,
  getContractStatusOptions,
  getContractTemplateOptions,
  getPrintConfirmationTemplatesByLanguage,
  getPrintConfirmationTemplatesExists,
  getCountryOptions,
  getDepartmentOptions,
  getEmailBounceBackOptions,
  getJobFunctionOptions,
  getDowngradeReasonOptions,
  getLevel2BusinessPartners,
  getLegalEntityTypeOptions,
  getNkamStatusOptions,
  getOwningRetailerContactOptions,
  getOwningRetailerOptions,
  getPositionOptions,
  getPrimaryLanguageOptions,
  getSalesChannelOptions,
  getSalesRegionOptions,
  getSubTypeOptions,
  getTypeOptions,
  getUpfrontDiscountMatrixOptions,
  getFilteredUpfrontDiscountMatrixOption,
  sendForApproval,
  getDunsMarketConfig,
  getCrmConfiguration,
  getCountyList,
  getInvoiceLanguageOptions,
  getBPRoleTypeOptions
};

export default apiUIData;
