import i18n from "@/configuration/i18n";
import {
  BAD_REQUEST_400,
  FORBIDDEN_403,
  INTERNAL_SERVER_ERROR_500,
  NOT_FOUND_404,
  UNAUTHORISED_401,
} from "@/constants/errors";

const handleReturnedError = (err, reject) => {
  reject(err);
};
const statusCodeTextLookUp = (status) => {
  let errorTitle;
  if (status === BAD_REQUEST_400) {
    errorTitle = i18n.t("webapi.errorMessages.badRequest");
  } else if (status === UNAUTHORISED_401) {
    errorTitle = i18n.t("webapi.errorMessages.unAuthorised");
  } else if (status === FORBIDDEN_403) {
    errorTitle = i18n.t("webapi.errorMessages.forbidden");
  } else if (status === NOT_FOUND_404) {
    errorTitle = i18n.t("webapi.errorMessages.notFound");
  } else if (status === INTERNAL_SERVER_ERROR_500) {
    errorTitle = i18n.t("webapi.errorMessages.internalServerError");
  }
  return errorTitle;
};
export { handleReturnedError, statusCodeTextLookUp };
