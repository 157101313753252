import React from "react";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Tooltip from "@mui/material/Tooltip";
import TooltipConditionalWrapper from "@/components/TooltipConditionalWrapper";
import { convertGMTDateToISO } from "@/utils/convertGMTDateToISO";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";

const DateController = ({
  control,
  disabled,
  label,
  name,
  required,
  shouldUnregister,
  title,
  testid
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({
          field: { name, onChange, ref, value },
          fieldState: { error },
        }) => (
          <TooltipConditionalWrapper
            condition={title}
            wrapper={(children) => (
              <Tooltip title={title} >{children}</Tooltip>
            )}
          >
            <DatePicker
              clearable
              classes={{
                root: {
                  "& .MuiIconButton-root": {
                    padding: 0,
                  },
                },
              }}
              format="dd/MM/yyyy"
              disabled={disabled}
              label={label}
              error={!!error}
              required={required}
              helperText={error ? error.message : null}
              keyboardIcon={
                disabled ? (
                  <LockOutlinedIcon />
                ) : (
                  <CalendarTodayOutlinedIcon />
                )
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputRef={ref}
              onChange={(date) => onChange(convertGMTDateToISO(date))}
              name={name}
              value={value ? parseISO(value) : value}
              slotProps={{
                textField: {
                  InputLabelProps: {
                    shrink: true
                  },
                  required: required,
                  'data-testid': testid,
                  error: !!error,
                  helperText: error ? error.message : null,
                }
              }}
            />
          </TooltipConditionalWrapper>
        )}
        rules={{
          required: required && "This field is required",
        }}
      />
    </LocalizationProvider>
  );
};

export default DateController;
